import React from 'react'
import { useSelector } from 'react-redux'

import { MAP_URL } from 'common/Map/const'

import ChartisLayer from 'common/Map/Layers/ChartisLayer'

function ObjectsLayer() {
  const { displayedLayers, layerFilters } = useSelector(state => state.map)
  const configuredLayers = useSelector(state => state.chartis.configuredLayers)

  const sorted = [...displayedLayers].sort(
    (a, b) => (configuredLayers[a].weight || 0) - (configuredLayers[b].weight || 0),
  )

  const mapLayer = (name, index) => (
    <ChartisLayer
      key={name}
      mapUrl={MAP_URL}
      sourceLayer={configuredLayers[name].view_slug}
      sourceTable={name}
      layerProps={configuredLayers[name].props}
      filters={layerFilters[name]}
      next={index !== 0 ? sorted[index - 1] : undefined}
    />
  )

  return (
    <div>
      {sorted.map((name, index) => mapLayer(name, index))}
    </div>
  )
}

export default ObjectsLayer
