export const MAIN_CONFIG = {
  app_name: 'LAMARR - Crise du rail',
  access_perm: 'lamarr::ACCESS',
  layers: {
    armen_troncon_v2: {
      view_slug: 'geo',
      props: {
        type: 'line',
        paint: {
          'line-color': [
            'case',
            ['==', ['get', 'categorie'], 1],
            '#FF0D0D',
            ['==', ['get', 'categorie'], 2],
            '#FF4E11',
            ['==', ['get', 'categorie'], 3],
            '#FF8E15',
            ['==', ['get', 'categorie'], 4],
            '#FAB733',
            ['==', ['get', 'categorie'], 5],
            '#39AA13',
            ['==', ['get', 'categorie'], -1],
            '#686868',
            '#499bbc',
          ],
          'line-width': 2,
        },
        legend: [
          {
            category: -1,
            color: '#686868',
          },
          {
            category: 1,
            color: '#FF0D0D',
          },
          {
            category: 2,
            color: '#FF4E11',
          },
          {
            category: 3,
            color: '#FF8E15',
          },
          {
            category: 4,
            color: '#FAB733',
          },
          {
            category: 5,
            color: '#39AA13',
          },
        ],
      },
    },
    armen_chantiers: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': {
            property: 'pourcentageUrgence0',
            stops: [
              [0, 'white'],
              [100, '#0088ce'],
            ],
          },
        },
      },
    },
    armen_chantiers_bqtx: {
      view_slug: 'geo',
      props: {
        type: 'fill',
        paint: {
          'fill-color': {
            property: 'longueur',
            stops: [
              [0, 'white'],
              [100, '#ce4500'],
            ],
          },
        },
      },
    },
  },
}

export default MAIN_CONFIG
