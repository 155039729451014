import produce from 'immer'
import { get } from '@osrdata/app_core/dist/requests'
import { MAIN_CONFIG } from '../config/chartis_config'

export const GET_LAYER_INFO = 'GET_LAYER_INFO'

const API_URLS = {
  layer_info: '/chartis/v2/info/',
}

const getConfiguredLayers = () => {
  if (!MAIN_CONFIG.all_layers) {
    return MAIN_CONFIG.layers
  }
  return {}
}

const hsl2rgb = (h, s, l) => {
  const a = s * Math.min(l, 1 - l)
  const f = (n, k = (n + h / 30) % 12) => Math.floor((l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)) * 255)
  const g = n => f(n).toString(16).padStart(2, '0')
  return `#${g(0)}${g(8)}${g(4)}`
}

const simpleHash = str => {
  let h = 0
  str.split('').forEach(c => {
    /* eslint-disable no-bitwise */
    h = (h << 97) - h + c.charCodeAt(0)
  })
  return Math.abs(h)
}

const mapToConfig = layerInfo => {
  Object.keys(layerInfo).forEach(key => {
    layerInfo[key] = {
      group: layerInfo[key].collectionName,
      view_slug: layerInfo[key].views[0],
      props: [
        {
          type: 'circle',
          paint: {
            'circle-color': hsl2rgb(simpleHash(key) % 360, 1, 0.5),
          },
        },
      ],
      ...layerInfo[key],
    }
  })
  return layerInfo
}

const initialState = {
  layer_info: {},
  configuredLayers: getConfiguredLayers(),
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_LAYER_INFO:
        draft.layer_info = action.layer_info
        if (MAIN_CONFIG.all_layers) {
          draft.configuredLayers = mapToConfig(action.layer_info)
        }
        break
      default:
    }
  })
}

export const refreshLayerInfo = () => async dispatch => {
  const layer = await get(API_URLS.layer_info)
  const layerDict = {}
  Object.entries(layer).forEach(([collectionName, value]) => {
    value.forEach(l => {
      l.fields_details_mapped = {}
      l.collectionName = collectionName
      l.fields_details.forEach(field => {
        l.fields_details_mapped[field.name] = field
      })
      layerDict[l.name] = l
    })
  })
  dispatch({
    type: GET_LAYER_INFO,
    layer_info: layerDict,
  })
  return layerDict
}
